.header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0px;
    z-index: 99999;
    -webkit-transform: translateZ(0px);
    /* --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --tw-ring-offset-shadow: 0 0 transparent; */
    /* backdrop-filter: blur(10px); */
    background-color: rgb(13 27 26)
}

@media screen and (min-width: 1440px) {
    .hero{
        min-height: 100vh;
    }
  }
@media screen and (max-width: 960px) {
    .header{
        transition: all 0.8s ease;
    }
  }
  .text-slider {
    width: 100%;
    height: 150px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .fade-slide {
    position: absolute;
    opacity: 0;
    transform: translateY(10px);
    animation: fadeInUp 0.5s ease-in-out forwards;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  